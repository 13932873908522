import {
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Button,
  } from "@mui/material";
  import Header from "../../../components/Header";
  import { useEffect, useState } from "react";
  import axios from "axios";
  import { GLOBAL_API } from "../../../data/Links";
  import { getAuthentication } from "../../../auth";
  
  const API_URL = `${GLOBAL_API}/gql`;
  
  const ArticleExtractor = () => {
    const [counter, setCounter] = useState(0);
    const [lastCount, setLastCount] = useState(0);
    const [categoriesList, setCategoriesList] = useState([]);
    const [category, setCategory] = useState("");
    const [logs, setLogs] = useState([]); // State to store the log messages
  
    const ExtractData = async () => {
        for (let i = counter + 1; i < lastCount + 1; i++) {
          try {
            const response = await axios.get(`https://extractor.time4.news/${i}/${category}`);
            if (response.status === 201) {
              const { title, _id } = response.data;
              // Prepend log message to keep the order descending
              setLogs((prevLogs) => [
                { id: _id, title: title, status: "Success" },
                ...prevLogs,
              ]);
            }
          } catch (error) {
            // Log the error in descending order
            setLogs((prevLogs) => [
              { id: i, title: "Failed to extract", status: "Error" },
              ...prevLogs,
            ]);
          }
        }
        await axios.post(API_URL,{
            query:`mutation{
                SetCounter(key:"ExtractArticle",value:${lastCount}){
                    value
                }
            }`
        },
        {
          headers: {
            token: `Bearer ${getAuthentication()}`,
          },
        })
        await getCounter('ExtractArticle')
      };
  
    const handleSubmit = () => {
      if (category && lastCount > counter) {
        setLogs([]); // Clear previous logs before starting
        ExtractData(); // Call the extraction function
      }
      else{
        console.log(category,lastCount,counter)
      }
    };
  
    const getCounter = async (key) => {
      const res = await axios.post(
        API_URL,
        {
          query: `query{
            GetCounter(key:"${key}"){
              value
            }
          }`,
        },
        {
          headers: {
            token: `Bearer ${getAuthentication()}`,
          },
        }
      );
      if (res.status === 200) {
        setCounter(res.data.data.GetCounter.value);
      }
    };
  
    const fetchData = async () => {
      try {
        const res = await axios.post(
          API_URL,
          {
            query: `query{
              GetAllArticleCategory{
                id
                MainInfo{
                  title
                }
                seo{
                  metaDescription
                  metaKeywords
                }
                info{
                  articleCount
                }
              }
            }`,
          },
          {
            headers: {
              token: `Bearer ${getAuthentication()}`,
            },
          }
        );
        if (res.status === 200) {
          setCategoriesList(res.data.data.GetAllArticleCategory);
        }
      } catch (err) {
        console.error("Error fetching categories", err);
      }
    };
  
    useEffect(() => {
      getCounter("ExtractArticle");
      fetchData();
    }, []);
  
    return (
      <Box m="20px">
        <Header title={`Article Extractor`} subtitle={`Extract Articles From Koora Website`} />
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          <FormControl sx={{ width: 1 / 4 }}>
            <InputLabel color="secondary" id="demo-simple-select-label">
              Select Category ...
            </InputLabel>
            <Select
              variant="filled"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              labelId="demo-simple-select-label"
              color="secondary"
              fullWidth
              label="Select Category ..."
            >
              {categoriesList.map((cat) => (
                <MenuItem key={cat.id} value={cat.id}>
                  {cat.MainInfo.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Starting From"
            type="number"
            variant="filled"
            color="secondary"
            value={counter}
            disabled={true}
          />
          <TextField
            label="Ending to"
            type="number"
            variant="filled"
            min={counter}
            color="secondary"
            value={lastCount}
            onChange={(e) => setLastCount(parseInt(e.target.value))}
          />
          <Button variant="contained" color="secondary" onClick={handleSubmit}>
            Extract Data
          </Button>
        </Box>
  
        {/* Real-time log updates */}
        <Box mt={4}>
        <h3>Extraction Logs</h3>
        <ul>
          {logs.map((log, index) => (
            <li key={index}>
              {log.status === "Success" ? (
                <span style={{ color: "green" }}>Success:</span>
              ) : (
                <span style={{ color: "red" }}>Error:</span>
              )}{" "}
              {log.title} (ID: {log.id})
            </li>
          ))}
        </ul>
      </Box>
      </Box>
    );
  };
  
  export default ArticleExtractor;
  