import { Box,useTheme,Pagination,Typography,TableContainer,Table,TableBody,TableHead,TableCell,Paper,TableRow,TablePagination,Button,IconButton,Modal} from "@mui/material"

import React, { useEffect, useState } from 'react'
import { GLOBAL_API } from '../../../data/Links';
import Header from '../../../components/Header';
import { tokens } from "../../../theme";
import CircularProgress from '@mui/material/CircularProgress';
import { getAuthentication } from "../../../auth";
import axios from "axios";
import AddNewMedia from "../../../components/Media/AddNewMedia";
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LaunchIcon from '@mui/icons-material/Launch';



const API_URL = `${GLOBAL_API}/gql`;
const PortfolioGQLSchema = `id
MainInfo{
  title
  slug
  names{
    ar
    en
  }
  startDate{
    isBirth
    date
  }
  endDate{
    isDeath
    date
  }
  startPlace{
    isBirth
    place
  }
  owners
  category{
      id
      MainInfo{
          title
          slug
      }
  }
  author{
    exist
    author_name
  }
  source{
      exist
      name
      link
  }
}
MainImage{
  alt
  filepath
}
content{
    id 
    title
    text
}
seo{
  description
  keywords
}

options{
  published
  done
}
infos{
  views
  createdAt
}`

const MediaGQLSchema = `
id
MainInfo{
    Title
    Description
    Category{
         id
            MainInfo{
                title
                slug
            }
    }
    Tags{
        ${PortfolioGQLSchema}
    }
    Keywords
}
MainImage{
    Type
    Link
}
Infos{
    Views
    CreatedAt
}
`

const Media = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [modalOpen,setModalOpen] = useState(false)
    const [modalStatus,setModalStatus] = useState('ADD')
    const [isLoaderVisible,setLoaderVisibility] = useState('visible')
    const [mediasCount,setMediasCount] = useState(0)
    const [isNewMedia,setIsNewMedia] = useState(false)
    const [medias,setMedias] = useState([])
    const handleCloseModal = () =>{
        setModalOpen(false)
    }
    const HandleMainInfoSubmit = async(title,category,description,tags,keywords,type,img) =>{
        addNewMediaItem(title,category,description,tags,keywords,type,img)
    }

    const ModalComponents = {
        "ADD": <AddNewMedia HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>
    }


    const handleNewBtnClick = () =>{
        setIsNewMedia(true)
        setModalOpen(true)
        setModalStatus('ADD')

    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
      }

      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0)
      }

      const handleDeleteClick = async(prtf) =>{
        try {
           await axios.post(API_URL, {
            query: `
              mutation {
                DeleteMedia(id:"${prtf.id}") 
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
  
          fetchData()
  
        } catch (error) {
        }
      }

    const addNewMediaItem = async(title,category,description,tags,keywords,type,img) =>{
        const res = await axios.post(API_URL, {
            query: `
              mutation {
                AddNewMedia(title:"${title}",category:"${category}",description:"${description}",tags:"${tags}",keywords:"${keywords}",type:"${type}",link:"${img}") {
                  ${MediaGQLSchema}
                }
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
    
          if(res.data.data.AddNewMedia.id !== null){
            fetchData()
          }
    }

    const getCounter = async(key) =>{
        const res = await axios.post(API_URL,{
            query:`query{
              GetCounter(key:"${key}"){
                value
              }
            }`
        },{
          headers:{
            token:`Bearer ${getAuthentication()}`
          }
        })
        if(res.status === 200){
          setMediasCount(res.data.data.GetCounter.value)
          setLoaderVisibility('hidden')
        }
    }



    const fetchData = async () => {
        setLoaderVisibility('visible')
  
        try{
     
       const res = await axios.post(API_URL,{
            query:`query{
              GetAllMedias(sort:"-1"){
                ${MediaGQLSchema}
              }
            }`
        },{
          headers:{
            token:`Bearer ${getAuthentication()}`
          }
        })
        if(res.status === 200){
          setMedias(res.data.data.GetAllMedias)
          getCounter('medias')

          setLoaderVisibility('hidden')
  
        }
      }
      catch(err){
  
      }
      }

      useEffect(()=>{
        fetchData()
      },[])

      useEffect(()=>{
        fetchData()
      },[page,rowsPerPage])


  return (
    <Box m="20px">
    <Header title={`Media`} subtitle={`Managing Media`} />
    <Modal open={modalOpen}
           aria-labelledby="modal-modal-title"
           aria-describedby="modal-modal-description">
          <>
         
            {ModalComponents[modalStatus]}

          </>
    </Modal>
    <Box sx={{
        mb:2,
        display:"flex",
        justifyContent:"space-between"
    }}>
      <Box sx={{
        display:"flex",
        visibility:isLoaderVisible,
        justifyContent:"center",
        alignContent:"center",
        alignItems:"center",
    }}>
                <CircularProgress color="secondary"  sx={{mx:2}}/>
                Fetching Data ... Please Wait
            </Box>
            <Button variant="contained" onClick={handleNewBtnClick} color="secondary">Add New Media</Button>
    </Box>
    <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Game Title</TableCell>
        <TableCell>Category</TableCell>
        <TableCell>Created At</TableCell>
        <TableCell>Type</TableCell>
        <TableCell>Views</TableCell>
        <TableCell>Preview</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
    
    medias.slice(page * rowsPerPage, page * rowsPerPage  + rowsPerPage).map(art => (
        <TableRow key={art.id}>
        <TableCell>{art.MainInfo.Title}</TableCell>
        <TableCell>{art.MainInfo.Category.MainInfo.title}</TableCell>
        <TableCell>{new Date(art.Infos.CreatedAt).toLocaleString()}</TableCell>
        <TableCell>{art.MainImage.Type === "IMG" ? 'Image' : "Video"}</TableCell>
        <TableCell> <IconButton>
                <Typography sx={{mx:1}}>{art.Infos.Views}</Typography>
                <VisibilityIcon />
            </IconButton>
</TableCell>
        <TableCell>
        <LaunchIcon />
           
        </TableCell>
        <TableCell>
      
      <IconButton color="error" onClick={() => handleDeleteClick(art)}>
        <DeleteIcon />
      </IconButton>
      
        </TableCell>
      </TableRow>
  
))

    }
    </TableBody>
  </Table>
</TableContainer>   
<TablePagination
  rowsPerPageOptions={[1,5,10, 25, 50, 100]}
  component="div"
  count={mediasCount}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>         
        </Box>
    </Box>
  )
}

export default Media