import { useState,useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme,Divider} from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";

import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CategoryIcon from '@mui/icons-material/Category';
import ArticleIcon from '@mui/icons-material/Article';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ImageIcon from '@mui/icons-material/Image';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import PollIcon from '@mui/icons-material/Poll';
import PermMediaIcon from '@mui/icons-material/PermMedia';
const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = ({Userpermissions}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");


  return (
    <Box
      sx={{
        position:"sticky",
        top:"0",
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  Time4.News
                </Typography>
              
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}

{!isCollapsed && (
     <Box
     display="flex"
     justifyContent="space-between"
     alignItems="center"
     ml="15px"
   >
    <Typography variant="h6" color="secondary">
    Powered by ULDASH
  </Typography>
  </Box>

)}
            
          </MenuItem>

         

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>

          {Userpermissions.banner && (
            <>
            <Item key={2} title="Banner Ads" to="/BannerAds" icon={<ImageIcon />} selected={selected} setSelected={setSelected} />
            <Divider />
            </>
          )}

            {Userpermissions.article ? (
             <>
              <Item key={1} title="Article Categories" to="/ArticleCategory" icon={<CategoryIcon />} selected={selected} setSelected={setSelected} />
              <Item key={1} title="Articles" to="/Articles" icon={<ArticleIcon />} selected={selected} setSelected={setSelected} />
              <Item key={1} title="Articles Top Tags" to="/ArticlesTopTags" icon={<ArticleIcon />} selected={selected} setSelected={setSelected} />
              <Item key={1} title="Articles Extractor" to="/ArticlesExtractor" icon={<ArticleIcon />} selected={selected} setSelected={setSelected} />
              <Divider />
             </>
            ):(<></>) } 
            

            {Userpermissions.portfolio ? (
              <>
                <Item key={1} title="Portfolio Categories" to="/PortfolioCategory" icon={<CategoryIcon />} selected={selected} setSelected={setSelected} />
                <Item key={1} title="Portfolio" to="/Portfolio" icon={<AccountBoxIcon />} selected={selected} setSelected={setSelected} />
                <Divider />
              </>
            ):(<></>) } 

            {Userpermissions.portfolio ? (
              <>
                <Item key={1} title="Game Categories" to="/GameCategory" icon={<CategoryIcon />} selected={selected} setSelected={setSelected} />
                <Item key={1} title="Game" to="/Game" icon={<AccountBoxIcon />} selected={selected} setSelected={setSelected} />
                <Divider />
              </>
            ):(<></>) } 
            {Userpermissions.media ? (
              <>
                <Item key={1} title="Media Categories" to="/MediaCategory" icon={<CategoryIcon />} selected={selected} setSelected={setSelected} />
                <Item key={1} title="Media" to="/Media" icon={<PermMediaIcon />} selected={selected} setSelected={setSelected} />
                <Divider />
              </>
            ):(<></>) } 
            
         {/*   {Userpermissions.imageArchive ? (
             <>
              <Item key={1} title="Image Categories" to="/ImageCategory" icon={<CategoryIcon />} selected={selected} setSelected={setSelected} />
              <Item key={1} title="Images" to="/Images" icon={<ImageIcon />} selected={selected} setSelected={setSelected} />
              <Divider />
             </>
            ):(<></>) } 
            
            {Userpermissions.youtubeArchive ? (
              <>
                <Item key={1} title="Youtube Categories" to="/YoutubeCategory" icon={<CategoryIcon />} selected={selected} setSelected={setSelected} />
                <Item key={1} title="Youtube Archive" to="/Youtubes" icon={<YouTubeIcon />} selected={selected} setSelected={setSelected} />
                <Divider />
              </>
            ):(<></>) } */}
            
            {Userpermissions.poll ? (
              <>
                <Item key={1} title="Polls" to="/Poll" icon={<PollIcon />} selected={selected} setSelected={setSelected} />
                <Divider />
              </>
            ):(<></>) } 
           
         {/*   {Userpermissions.ads ? (
              <>
                 <Item key={1} title="ADS" to="/ADS" icon={<FormatPaintIcon />} selected={selected} setSelected={setSelected} />
                 <Divider />
              </>
            )  :(<></>) } */ }

         
           
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;