import { Box, Button, TextField,useTheme,Typography,Switch,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../theme";
import Tags from "@yaireo/tagify/dist/react.tagify" 
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import { ReactTagify } from "react-tagify";
import { getAuthentication } from "../../auth";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close';

import { GLOBAL_API, UPLOAD_API } from "../../data/Links";

const API_URL = `${GLOBAL_API}/gql`;

const AddNewMedia = ({HandleMainInfoSubmit,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const tagifyRef = useRef()

  const [searchQuery,setSearchQuery] = useState('')

  const [addedPortfolios,setAddedPortfolios] = useState([])
  const [fetchedPortfolios,setFetchedPortfolios] = useState([])
  const [isVideo,setIsVideo] = useState(false)
  const [videoLink,setVideoLink] = useState('')
  const [uploadedCount,setUploadedCount] = useState(0)

  const [title,setTitle] = useState('')
  const [category,setCategory] = useState('')
  const [description,setDescription] = useState('')
  const [keywords,setKeywords] = useState([])

  const [selectedImages, setSelectedImages] = useState([])
  const [images,setImages] = useState([])

  const [categoriesList,setCategoriesList] = useState([])

  
  const HandleSubmitBtn = async() =>{
    let tags = []
    console.log(images)
    addedPortfolios.map((prt)=>{
        tags.push(prt.id)
    })
    for(const img of images){
        HandleMainInfoSubmit(title,category,description,tags.join(','),keywords,isVideo ? 'VID' : 'IMG',img)
        await new Promise((resolve) => setTimeout(resolve,  1500)); // Add 500ms delay
    }
    closingModal()
  }

  const AddToVideoLink = () =>{
    setImages(prevImages => [...prevImages, videoLink])
    setVideoLink('')
  }

  const onSelectFile = (e) => {
    const files = e.target.files;
    setSelectedImages([...files]);
  };

  const handleImageUpload = async () => {
    for (const image of selectedImages) {
      await uploadImage(image);
      await new Promise((resolve) => setTimeout(resolve,  1500)); // Add 500ms delay
      setUploadedCount(prevCount => prevCount  + 1)

    }
  };
  

  const uploadImage = async(image) =>{
    const formData = new FormData();
    formData.append("image", image)
    const res = await axios.post(UPLOAD_API, formData, {
        headers: {
          token: `Bearer ${getAuthentication()}`,
          "Content-Type": "multipart/form-data",
        }
      })
      if (res.status === 201) {
        const name = res.data.name
        setImages(prevImages => [...prevImages, name]);
    }
  }

  const removeAddedPortfolio = (id) =>{
    setAddedPortfolios(addedPortfolios.filter((ap)=> ap.id !== id))
  }
  
  const addToAddedPortfolio = (prt) =>{
    setAddedPortfolios([...addedPortfolios,prt])
  }

  const onSearchPortfolio = async(query) =>{
        if(query.length>2){
            setFetchedPortfolios([])
            await fetchPortfolioData(query)
            setSearchQuery(query)
        }
        else{
            setFetchedPortfolios([])

        }
      
  }

  const fetchPortfolioData = async(query) =>{
    try{
 
        const res = await axios.post(API_URL,{
             query:`query{
               SearchInPortfolio(text:"${query}"){
                    id
                    MainInfo{
                        title
                    }
             }
        }`
         },{
           headers:{
             token:`Bearer ${getAuthentication()}`
           }
         })
         if(res.status === 200){
            setFetchedPortfolios(res.data.data.SearchInPortfolio)
         }
       }
       catch(err){
     
       }
  }

  const fetchData = async () => {
    try{
 
   const res = await axios.post(API_URL,{
        query:`query{
          GetAllMediaCategory{
            id
            MainInfo{
              title
            }
            seo{
              metaDescription
              metaKeywords
            }
            info{
              articleCount
            }
          }
        }`
    },{
      headers:{
        token:`Bearer ${getAuthentication()}`
      }
    })
    if(res.status === 200){
      setCategoriesList(res.data.data.GetAllMediaCategory)
    }
  }
  catch(err){

  }
  }

  useEffect(() => {
    fetchData()
    }, [])

  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "60%",
  height:"95%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
      
      
      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>Add New Media</Typography>
     
      <Formik
       
> 
{({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
            <TextField 
              sx={{
                mt:3
              }}
              fullWidth 
              variant="filled"
              type="text"
              value={title}
              onChange={(e)=>setTitle(e.target.value)}
              label="Media Title ..."
              color="secondary"
            />
            <FormControl fullWidth sx={{ mt: 2, minWidth: 120 }}>
              <InputLabel color="secondary" id="demo-simple-select-label">Select Category ...</InputLabel>
                <Select variant="filled"  value={category} onChange={(e)=>setCategory(e.target.value)}   labelId="demo-simple-select-label"  color="secondary" fullWidth label="Select Category ..." >
                  {categoriesList.map((cat) => (
                      <MenuItem key={cat.id} value={cat.id}>
                        {cat.MainInfo.title}
                      </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Tags  defaultValue={keywords} tagifyRef={tagifyRef} color="secondary" settings={{
        placeholder:"Seo Keywords ..."
      }} onChange={(e) => setKeywords(e.detail.tagify.value.map((tag) => tag.value))} />
      <TextField
fullWidth
          id="filled-multiline-static"
          label="Seo Description ..."
          multiline
          rows={4}
          sx={{ gridColumn: "span 2",mt:3 }}
          onChange={(e)=>setDescription(e.target.value)}
          defaultValue={description}
          variant="filled"
          color="secondary"

        />
        <Box>
        <TextField 
              sx={{
                mt:3
              }}
              fullWidth 
              variant="filled"
              type="text"
              onChange={(e)=>onSearchPortfolio(e.target.value)}
              label="Portfolio Search ..."
              color="secondary"
            />
        <ul style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:4,justifyContent:"flex-start",padding:0}}>
              {
                fetchedPortfolios.map((ftPrt)=>(
                    <Button  variant="contained" color="warning"  onClick={()=>addToAddedPortfolio(ftPrt)} >{ftPrt.MainInfo.title}</Button>
                ))
              }
        </ul>
        <Typography>Added Portfolios</Typography>
        <ul style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:6,padding:0}}>
            {
                addedPortfolios.map((aP)=>(
                    <li style={{listStyle:"none",backgroundColor:"green",padding:4,borderRadius:5}}>{aP.MainInfo.title} <Button onClick={()=>removeAddedPortfolio(aP.id)} style={{background:'none',border:'none',padding:0,margin:0}}><CloseIcon style={{fontSize:12}} /></Button></li>
                ))
            }
        </ul>
        </Box>
        <Box>
            <Switch value={isVideo} onChange={()=>setIsVideo(!isVideo)} color="secondary" />
            <span>Is Video</span>
        </Box>
        {
            isVideo ? <Box>
                <Box sx={{display:"flex",alignItems:"center",gap:4}}>
                    <TextField 
                        sx={{
                            width:"75%"
                        }}
                        variant="filled"
                        type="text"
                        value={videoLink}
                        onChange={(e)=>setVideoLink(e.target.value)}
                        label="Video Link ..."
                        color="secondary"
                        />
                    <Button color="secondary" onClick={AddToVideoLink} variant="contained">Add To Video Links</Button>
                </Box>
                <ul>
                    {
                        images.map((vid)=>(<li>{vid}</li>))
                    }
                </ul>
            </Box> : <>
            <Box sx={{ mt: 2 }}>
              <Button
                color="primary"
                variant="contained"
                component="label"
              >
                Browse Images
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={onSelectFile}
                  hidden
                />
              </Button>

              <Button
                sx={{ mx: 2 }}
                color="warning"
                variant="contained"
                onClick={handleImageUpload}
              >
                Upload Images
              </Button>
            </Box>

            {/* Display selected image filenames */}
            {selectedImages.length > 0 && (
              <Box sx={{ mt: 2 }}>
                <Typography>Selected Images:</Typography>
                <ul>
                  {selectedImages.map((image, index) => (
                    <li key={index}>{image.name}</li>
                  ))}
                </ul>
              </Box>
            )}
            <Typography>{uploadedCount} / {selectedImages.length} Uploaded</Typography>
            </>
        }
        

    
        <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">Add New Media</Button>
       
        </form>
      )}
   
      </Formik>
    
    </Box>
  );
};
export default AddNewMedia;